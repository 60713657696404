import { Inject, Injectable, InjectionToken, Optional, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormComponent } from '@woolworthsnz/form';
import { REGISTRATION_SUCCESSFUL_PATH } from '@woolworthsnz/shop';
import {
	ApiService,
	AppSettingsService,
	AuthFacade,
	CustomWindow,
	DatalayerService,
	GigyaService,
	InboundEmailParamsService,
	OLIVE_CHAT_SESSION_STORAGE_KEY,
	RegistrationCompleteEvent,
	ShopperService,
	WINDOW,
} from '@woolworthsnz/styleguide';
import { SignInResponse } from '@woolworthsnz/trader-api';
import { throwError } from 'rxjs';
import { catchError, finalize, take } from 'rxjs/operators';
import { AppService } from '../../core/services/app.service';
import { isPlatformServer } from '@angular/common';

const DATALAYER_REGISTRATION_COMPLETE = 'complete';

@Injectable({
	providedIn: 'root',
})
export class AuthenticationService {
	gigyaJwtCookieName = 'cw-ssoc';

	constructor(
		private apiService: ApiService,
		private gigyaService: GigyaService,
		private datalayerService: DatalayerService,
		private appSettingsService: AppSettingsService,
		private shopperService: ShopperService,
		private router: Router,
		private authFacade: AuthFacade,
		private route: ActivatedRoute,
		private inboundEmailParamsService: InboundEmailParamsService,
		@Inject(WINDOW) private window: CustomWindow,
		@Optional() private appService: AppService,
		@Inject(PLATFORM_ID) private platform: InjectionToken<Object>
	) {}

	get logoutEndpoint(): string {
		return this.appSettingsService.getEndpoint('logout');
	}

	init(): void {
		if (isPlatformServer(this.platform)) {
			return;
		}
		if (this.appSettingsService.useSSU()) {
			this.handleInboundEmailParams();
		} else {
			this.gigyaService.initGigya();
		}
	}

	initialiseSSU(): void {
		this.authFacade.initialise();
	}

	handleInboundEmailParams(): void {
		// if we're on an inbound link from an email we need to ensure their session has the correct store and/or onecard
		if (this.inboundEmailParamsService.isFromEmail()) {
			//  has "redirected" url params and have token or store in url parmas, so setParameters directly
			if (this.inboundEmailParamsService.isFromEmailandRedirected()) {
				return this.inboundEmailParamsService.setParameters();
			}
			// We don't have a session so skip straight to setting the store and/or onecard
			this.inboundEmailParamsService
				.isShoppersPrimaryOnecard()
				.pipe(take(1))
				.subscribe((isPrimary: boolean) => {
					// The onecard in the token is the current user's primary card, no need to log out
					// just set the store
					if (isPrimary) {
						return this.inboundEmailParamsService.setStore();
					}
					// if we're on an inbound link from an email and the onecard is not the current user's primary onecard
					// we sign them out and pass the onecard or Store parameters in the redirect url so we can set it on their sessio
					this.inboundEmailParamsService.setRedirected();
					this.doLogout();
				});
		}
	}

	doLogout(): void {
		const hasJWTCookie = this.window.document.cookie.indexOf(this.gigyaJwtCookieName) !== -1;
		if (this.appSettingsService.useSSU() && !hasJWTCookie) {
			this.ssuLogout();
		} else {
			this.gigyaLogout();
		}
	}

	ssuLogout(): void {
		this.window.sessionStorage?.removeItem(OLIVE_CHAT_SESSION_STORAGE_KEY);
		if (this.window.chatWidget?.updateUserToken) {
			this.window.chatWidget?.updateUserToken(null);
		}
		this.window.location.href = `${this.appSettingsService.getEndpoint(
			'oidcSignOutEndpoint'
		)}?redirectUrl=${encodeURIComponent(this.appSettingsService.getAuthRedirectUrl(this.window.location.href))}`;
	}

	gigyaLogout(): void {
		this.appService?.loading$.next(true);
		this.gigyaService.handleLogout(() => {
			this.apiService
				.post(this.logoutEndpoint, { gigyaInitiated: false })
				.pipe(
					take(1),
					catchError((e) => throwError(e)),
					finalize(() => {
						this.appService?.loading$.next(false);
						this.window.location = `${
							this.appSettingsService.settings.iamBaseUrl
						}/logout?redirect_uri=${encodeURIComponent(this.window.location.href)}`;
					})
				)
				.subscribe();
		});
	}

	handleSuccessfulRegistration(): Promise<boolean> {
		const datalayerEvent = new RegistrationCompleteEvent();
		datalayerEvent.scvRegistration = DATALAYER_REGISTRATION_COMPLETE;
		datalayerEvent.olsRegistration = DATALAYER_REGISTRATION_COMPLETE;
		this.datalayerService.pushToDatalayer(datalayerEvent);
		const returnUrl = this.route.snapshot.queryParams.returnUrl;
		return returnUrl
			? this.router.navigateByUrl(returnUrl, { replaceUrl: true })
			: this.router.navigateByUrl(`shop/${REGISTRATION_SUCCESSFUL_PATH}`);
	}

	handleSuccessfulAuthentication(response: SignInResponse, form: FormComponent): void | Promise<boolean> {
		const { isSuccessful, signInFailureReasonMessage } = response;

		if (isSuccessful) {
			const datalayerEvent = new RegistrationCompleteEvent();

			datalayerEvent.scvRegistration = DATALAYER_REGISTRATION_COMPLETE;
			datalayerEvent.olsRegistration = DATALAYER_REGISTRATION_COMPLETE;

			this.datalayerService.pushToDatalayer(datalayerEvent);

			const returnUrl = this.route.snapshot.queryParams.returnUrl;
			return returnUrl
				? this.router.navigateByUrl(returnUrl, { replaceUrl: true })
				: this.router.navigateByUrl(`shop/${REGISTRATION_SUCCESSFUL_PATH}`);
		}

		form.error = {
			type: 'error',
			message: signInFailureReasonMessage || '',
		};
	}
}
