import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TimeslotReservedRedirectGuard } from '@woolworthsnz/fulfilment';
import {
	ABOUT_US_PATH,
	ACCOUNT_PATH,
	CHANGE_ORDER_LANDING_PATH,
	CHECKOUT_HOW_WHERE_WHEN_PATH,
	DELIVERYSUBSCRIPTION_PATH,
	EDR_BOOST_OFFERS_PATH,
	ERROR_PATH,
	HAVE_YOU_FORGOTTEN_PATH,
	HOW_WHERE_WHEN_PATH,
	LEGACY_HAVE_YOU_FORGOTTEN_PATH,
	LISTS_PATH,
	MEAL_PLANNING_PATH,
	MODAL_ROUTES,
	NOT_FOUND_PATH,
	ORDER_CONFIRMATION_PATH,
	PAYMENTS_PATH,
	RECIPES_PATH,
	REVIEW_ORDER_PATH,
	SHOPPING_LISTS_PATH,
	STORE_LOCATOR_PATH,
	VIEW_TROLLEY_PATH,
} from '@woolworthsnz/shop';
import {
	BackToLegacyGuard,
	FeatureGuard,
	FeatureRedirectGuard,
	PreloadingStrategyService,
} from '@woolworthsnz/styleguide';
import { ShopperGuard } from './authentication/guards';
import {
	AcceptTsAndCsGuard,
	EmbeddedShopperGuard,
	EmptyBasketRedirectGuard,
	HaveYouForgottenPreferenceGuard,
	HowWhereWhenPageGuard,
} from './core/guards';
import { NewShopperGuard } from './core/guards/new-shopper.guard';
import { MandatoryLoginGuard } from './core/guards/mandatory-login-guard';

// 	Due to needing to prefix everything with shop, the guards can sometimes trip things up.
// 	If you're missing navigation, ensure that you haven't got a feature guard
// 	catching everything
export const routes: Routes = [
	{
		path: '',
		canActivateChild: [MandatoryLoginGuard],
		children: [
			{
				path: '',
				loadChildren: () => import('./home/home-routing.module').then((m) => m.routes),
				data: { feature: 'Home', preload: true, breadcrumb: false },
				canActivate: [EmbeddedShopperGuard, NewShopperGuard, MandatoryLoginGuard],
			},
			{
				path: 'shop',
				loadChildren: () => import('./product-detail/product-detail.module').then((m) => m.ProductDetailModule),
				data: { feature: 'ProductDetails', preload: true, breadcrumb: true },
			},
			{
				path: EDR_BOOST_OFFERS_PATH,
				loadComponent: () =>
					import('@woolworthsnz/everyday-rewards').then((mod) => mod.EDRBoostOffersPageComponent),
				data: {
					preload: false,
					breadcrumb: false,
				},
				canActivate: [ShopperGuard],
			},
			{
				path: SHOPPING_LISTS_PATH,
				pathMatch: 'full',
				loadChildren: () => import('./shopping-list/shopping-list-routing.module').then((m) => m.routes),
				data: {
					preload: false,
					breadcrumb: false,
				},
			},
			{
				loadChildren: () => import('./authentication/authentication-routing.module').then((m) => m.routes),
				path: 'shop',
				data: { preload: false, breadcrumb: false },
			},
			{
				loadChildren: () => import('./search/search-routing.module').then((m) => m.routes),
				path: 'shop',
				data: { preload: true, breadcrumb: true },
			},
			{
				path: HOW_WHERE_WHEN_PATH,
				loadChildren: () => import('./how-where-when/how-where-when.module').then((m) => m.HowWhereWhenModule),
				data: {
					feature: 'HowWhereWhen',
					preload: true,
					breadcrumb: false,
					hideFulfilmentBar: true,
				},
				canActivate: [HowWhereWhenPageGuard],
			},
			{
				path: CHECKOUT_HOW_WHERE_WHEN_PATH,
				loadChildren: () => import('./how-where-when/how-where-when.module').then((m) => m.HowWhereWhenModule),
				data: {
					feature: 'HowWhereWhen',
					preload: false,
					breadcrumb: false,
					hideFulfilmentBar: true,
					funnelHeader: true,
					isCheckout: true,
				},
				canActivate: [HowWhereWhenPageGuard],
			},
			{
				path: RECIPES_PATH,
				loadChildren: () => import('./recipes/recipes.module').then((m) => m.RecipesModule),
				data: {
					preload: false,
					breadcrumb: false,
				},
			},
			{
				path: MEAL_PLANNING_PATH,
				loadChildren: () => import('./meal-planning/meal-planning.module').then((m) => m.MealPlanningModule),
				canActivate: [FeatureGuard],
				data: {
					preload: false,
					breadcrumb: false,
					feature: 'MealPlanning',
				},
			},
			{
				path: ACCOUNT_PATH,
				loadChildren: () => import('./account/account-routing.module').then((m) => m.routes),
				data: {
					preload: false,
					breadcrumb: false,
					mainFlex: true,
				},
			},
			{
				path: CHANGE_ORDER_LANDING_PATH,
				loadChildren: () => import('./change-order/change-order-routing.module').then((m) => m.routes),
				canActivate: [ShopperGuard],
			},
			{
				path: VIEW_TROLLEY_PATH,
				loadChildren: () => import('./view-trolley/view-trolley-routing.module').then((m) => m.routes),
				data: {
					preload: false,
					breadcrumb: false,
				},
				canActivate: [ShopperGuard],
			},
			{
				path: HAVE_YOU_FORGOTTEN_PATH,
				loadChildren: () =>
					import('./have-you-forgotten/have-you-forgotten-routing.module').then((m) => m.routes),
				data: {
					feature: 'HaveYouForgotten',
					featureRedirectUrl: LEGACY_HAVE_YOU_FORGOTTEN_PATH,
					preload: false,
					breadcrumb: false,
					funnelHeader: true,
				},
				canActivate: [ShopperGuard, HaveYouForgottenPreferenceGuard, FeatureRedirectGuard],
			},
			{
				path: LISTS_PATH,
				loadChildren: () => import('./lists/list-routing.module').then((m) => m.routes),
				data: {
					preload: false,
					breadcrumb: false,
				},
			},
			{
				path: ABOUT_US_PATH,
				loadChildren: () => import('./about-us/about-us-routing.module').then((m) => m.routes),
				data: {
					preload: false,
					breadcrumb: true,
				},
			},
			{
				path: 'shop',
				children: [
					{
						path: 'content',
						loadChildren: () => import('./content/content-routing.module').then((m) => m.routes),
						data: {
							breadcrumb: true,
						},
					},
				],
			},
			{
				path: 'info',
				loadChildren: () => import('./content/content-routing.module').then((m) => m.routes),
				data: {
					breadcrumb: true,
				},
			},
			{
				path: REVIEW_ORDER_PATH,
				loadChildren: () => import('./review-order/review-order.routing.module').then((m) => m.routes),
				data: {
					funnelHeader: true,
					showChangeOrderFulfilmentBar: true,
				},
				canActivate: [TimeslotReservedRedirectGuard],
			},
			{
				path: PAYMENTS_PATH,
				loadChildren: () => import('./payment/payment.routing.module').then((m) => m.routes),
				data: {
					funnelHeader: true,
				},
				canActivate: [
					ShopperGuard,
					TimeslotReservedRedirectGuard,
					EmptyBasketRedirectGuard,
					AcceptTsAndCsGuard,
				],
				runGuardsAndResolvers: 'always',
			},
			{
				path: ORDER_CONFIRMATION_PATH,
				loadChildren: () =>
					import('./order-confirmation/order-confirmation-routing.module').then((m) => m.routes),
				data: {
					feature: 'OrderConfirmation',
				},
				canActivate: [BackToLegacyGuard],
				runGuardsAndResolvers: 'always',
			},
			{
				path: DELIVERYSUBSCRIPTION_PATH,
				loadChildren: () =>
					import('./delivery-subscription/delivery-subscription-routing.module').then((m) => m.routes),
				data: {
					preload: true,
					breadcrumb: false,
				},
			},
			{
				path: STORE_LOCATOR_PATH,
				loadChildren: () => import('./store-locator/store-locator-routing.module').then((m) => m.routes),
				data: {
					preload: true,
					breadcrumb: false,
					mainFlex: true,
				},
			},
			{
				path: ERROR_PATH,
				loadComponent: () => import('@woolworthsnz/styleguide').then((c) => c.ServerErrorComponent),
			},
			{
				path: NOT_FOUND_PATH,
				loadComponent: () => import('@woolworthsnz/styleguide').then((c) => c.NotFoundErrorComponent),
			},
			{
				path: '**',
				loadComponent: () => import('@woolworthsnz/styleguide').then((c) => c.NotFoundErrorComponent),
			},
		],
	},
	{
		path: '',
		outlet: MODAL_ROUTES.MODAL_OUTLET,
		loadChildren: () => import('./auxiliary-modals/auxiliary-modals-routing.module').then((m) => m.routes),
		data: {
			preload: true,
		},
	},
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			preloadingStrategy: PreloadingStrategyService,
			initialNavigation:
				typeof process !== 'undefined' && !!process?.env.SSR ? 'enabledBlocking' : 'enabledNonBlocking',
			scrollPositionRestoration: 'disabled',
			onSameUrlNavigation: 'reload',
		}),
	],
	exports: [RouterModule],
})
export class ShopUIRoutingModule {}
