export enum NewLayoutOfProductCategoriesAndFiltersVariants {
	Off = 'off',
	Control = 'control',
	V3 = 'v3',
}

export interface NewLayoutOfProductCategoriesAndFiltersVariables {
	searchTargets: string;
}

export enum NewLayoutOfProductCategoriesAndFiltersExp2Variants {
	Off = 'off',
	Control = 'control',
	V1 = 'v1',
	V2 = 'v2',
}

export enum NewLayoutOfProductCategoriesAndFiltersExp3Variants {
	Off = 'off',
	Control = 'ab152-control',
	V1 = 'ab152-v1',
	V2 = 'ab152-v2',
	V3 = 'ab152-v3',
}
