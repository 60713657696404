import { Injectable } from '@angular/core';
import { ActivatedRoute, QueryParamsHandling, Router, UrlSegment } from '@angular/router';
import { SearchLibService, SearchQueryParams, SearchState } from '@woolworthsnz/search';
import {
	AppSettingsService,
	BreadcrumbItem,
	FlagKey,
	FlagService,
	NewLayoutOfProductCategoriesAndFiltersExp2Variants,
	NewLayoutOfProductCategoriesAndFiltersVariants,
	SearchTarget,
	StatefulService,
} from '@woolworthsnz/styleguide';
import { Breadcrumb, FilterCriteria, ProductResponse, SearchResponse } from '@woolworthsnz/trader-api';
import { BreadCrumbService } from '../core/services/breadcrumb.service';
import { EDR_BOOST_OFFERS_PATH } from '@woolworthsnz/shop';
import { forkJoin, take } from 'rxjs';

export const boostShopRangeDefaultTitle = 'Product range';

const initialState: SearchState = {
	currentPage: 1,
	currentPageSize: 24,
	dasFacets: [],
	facets: [],
	brandSuggestions: [],
	search: '',
	groupId: 0,
	dynamicGroup: '',
	products: undefined,
	cumulativeProducts: {
		items: [],
		totalItems: 0,
	},
	dasFilter: undefined,
	dasFilters: [],
	selectedFilters: [],
	sortOptions: [],
	routeUrl: '',
	target: undefined,
	loading: false,
	scrollToTop: false,
	boostOfferTag: undefined, // For search results for the Boost Shop Range, all boostOfferTags will be the same, so only store one
	inStockProductsOnly: true,
	cppItemCount: 0,
	discount: undefined,
};

enum DAS {
	Department = 'Department',
	Aisle = 'Aisle',
	Shelf = 'Shelf',
}

@Injectable({ providedIn: 'root' })
export class SearchStoreService extends StatefulService<SearchState> {
	breadcrumb: Breadcrumb = {};

	private syncDasFacetsWithBreadCrumbShelf = true;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private breadCrumbService: BreadCrumbService,
		private appSettingsService: AppSettingsService,
		private searchLibService: SearchLibService,
		private flagService: FlagService
	) {
		super(initialState);

		forkJoin([
			this.flagService.getVariationKey<NewLayoutOfProductCategoriesAndFiltersVariants>(
				FlagKey.newLayoutOfProductCategoriesAndFilters
			),
			this.flagService.getVariationKey<NewLayoutOfProductCategoriesAndFiltersExp2Variants>(
				FlagKey.newLayoutOfProductCategoriesAndFiltersExp2
			),
		])
			.pipe(take(1))
			.subscribe((variants) => {
				const activeVariant = variants.find(
					(variant) => variant !== NewLayoutOfProductCategoriesAndFiltersVariants.Off
				);

				if (activeVariant) {
					this.syncDasFacetsWithBreadCrumbShelf = ![
						NewLayoutOfProductCategoriesAndFiltersVariants.V3,
						NewLayoutOfProductCategoriesAndFiltersExp2Variants.Control,
						NewLayoutOfProductCategoriesAndFiltersExp2Variants.V1,
						NewLayoutOfProductCategoriesAndFiltersExp2Variants.V2,
					].includes(activeVariant);
				}
			});
	}

	get url(): UrlSegment[] {
		return this.route.snapshot && this.route.snapshot.url;
	}

	get inStockProductsOnlyForSearchAndBrowse(): boolean {
		return (this.state.target === SearchTarget.Browse || this.state.target === SearchTarget.Search) &&
			typeof this.state.inStockProductsOnly !== 'undefined'
			? this.state.inStockProductsOnly
			: true;
	}

	get isSearchTarget(): boolean {
		return this.state.target === SearchTarget.Search;
	}

	get isProductGroup(): boolean {
		return (
			this.state.target === SearchTarget.ProductGroup || this.state.target === SearchTarget.DynamicProductGroup
		);
	}

	get isBoostOffer(): boolean {
		return this.state.target === SearchTarget.BoostOffer;
	}

	resetState(): void {
		this.setState(initialState);
	}

	getFilterGroups(): Array<FilterCriteria[]> {
		return this.searchLibService.getFilteredGroups(this.state.facets);
	}

	addFilter(filter: FilterCriteria): FilterCriteria[] {
		return [...this.state.selectedFilters, filter];
	}

	removeFilter(filter: FilterCriteria): FilterCriteria[] {
		return this.state.selectedFilters.filter(
			({ key, value }: { [key: string]: any }) => value !== filter.value || key !== filter.key
		);
	}

	handleFilterChange(filter: FilterCriteria, isSelected: boolean, url: UrlSegment[]): void {
		const selectedFilters: FilterCriteria[] = isSelected ? this.addFilter(filter) : this.removeFilter(filter);
		this.navigateOnFilterChange(selectedFilters, url);
	}

	handleFiltersChange(changes: Array<{ filter: FilterCriteria; isSelected: boolean; url: UrlSegment[] }>): void {
		if (changes.length > 0) {
			const url = changes[0].url;

			const selectedFilters: FilterCriteria[] = changes.reduce(
				(aggregateFilters, { filter, isSelected }) =>
					isSelected
						? [...aggregateFilters, filter]
						: aggregateFilters.filter(({ key, value }) => value !== filter.value || key !== filter.key),
				this.state.selectedFilters
			);

			this.navigateOnFilterChange(selectedFilters, url);
		}
	}

	navigateOnFilterChange(selectedFilters: FilterCriteria[], url: UrlSegment[]): void {
		const filterQueryParam = this.buildFilterQuery(selectedFilters);
		const route = this.breadCrumbService.buildUrlPath({
			breadcrumb: this.breadcrumb,
			urlSegments: url,
			target: this.state.target,
		});
		const params = {
			queryParams: {
				filters: filterQueryParam,
				page: 1,
				inStockProductsOnly: this.inStockProductsOnlyForSearchAndBrowse,
			},
			queryParamsHandling: 'merge' as QueryParamsHandling,
		};

		this.router.navigate([route], { ...params });
	}

	handleDasFiltersChange(
		changes: Array<{ dasFilter: FilterCriteria; isSelected: boolean; url: UrlSegment[] }>
	): void {
		if (changes.length > 0) {
			const url = changes[0].url;

			const dasFilters: FilterCriteria[] = changes.reduce(
				(aggregateFilters, { dasFilter, isSelected }) =>
					isSelected
						? [...aggregateFilters, dasFilter]
						: aggregateFilters.filter(
								({ key, value }) => value !== dasFilter.value || key !== dasFilter.key
						  ),
				this.state.dasFilters || []
			);

			const filterQueryParam = this.buildFilterQuery(dasFilters);
			const route = this.breadCrumbService.buildUrlPath({
				breadcrumb: this.breadcrumb,
				urlSegments: url,
				target: this.state.target,
			});
			const params = {
				queryParams: {
					dasFilter: filterQueryParam,
					page: 1,
					sort: this.state.currentSortOption,
				},
				queryParamsHandling: 'merge' as QueryParamsHandling,
			};

			this.router.navigate([route], { ...params });
		}
	}

	handleInStockProductsOnlyToggleChange(url: UrlSegment[]): void {
		const route = this.breadCrumbService.buildUrlPath({
			breadcrumb: this.breadcrumb,
			urlSegments: url,
			target: this.state.target,
		});
		const params = {
			queryParams: {
				page: 1,
				inStockProductsOnly: this.inStockProductsOnlyForSearchAndBrowse,
			},
			queryParamsHandling: 'merge' as QueryParamsHandling,
		};

		this.router.navigate([route], { ...params });
	}

	buildQuery(dasFilter = this.state.dasFilter, filters = this.state.selectedFilters): SearchQueryParams {
		const { currentPageSize, currentSortOption, search, dynamicGroup, groupId, discount } = this.state;

		const { department, aisle, shelf } = this.breadcrumb;
		const selectedFilters: FilterCriteria[] = [
			department ?? {},
			aisle ?? {},
			dasFilter?.key !== 'Shelf' && shelf ? shelf : {},
			dasFilter ?? {},
		];

		return {
			discount,
			search,
			dynamicGroup,
			groupId,
			page: 1,
			size: currentPageSize,
			sort: currentSortOption,
			dasFilter: dasFilter && this.buildFilterQuery(selectedFilters),
			filters: filters && this.buildFilterQuery(filters),
			inStockProductsOnly: this.inStockProductsOnlyForSearchAndBrowse,
		};
	}

	buildFilterQuery(selectedFilters: FilterCriteria[]): string[] | null {
		return this.searchLibService.buildFilterQuery(selectedFilters);
	}

	buildFilterQueryString(filter: FilterCriteria): string | null {
		return this.searchLibService.buildFilterQueryString(filter);
	}

	buildArrayOfStringifiedUrlSegments(url: UrlSegment[]): string[] {
		return this.searchLibService.buildArrayOfStringifiedUrlSegments(url);
	}

	isCurrentRouteDepartment = (): boolean =>
		Boolean(this.breadcrumb?.department && !this.breadcrumb?.aisle && !this.breadcrumb?.shelf);

	isCurrentRouteAisle = (): boolean => Boolean(this.breadcrumb?.aisle && !this.breadcrumb?.shelf);

	isCurrentRouteShelf = (): boolean => Boolean(this.breadcrumb?.shelf);

	parseFacet(facet: string): FilterCriteria {
		if (!facet) {
			return {};
		}
		const [key, value, name, isBooleanVal, group] = facet.split(';');
		return {
			key,
			value,
			name,
			isBooleanValue: isBooleanVal?.toLowerCase() === 'true',
			group,
		};
	}

	parseIndividualFilterCriteria(filter: string): FilterCriteria {
		if (!filter) {
			return {};
		}
		const [key, value, name, isBooleanValue, group] = filter.split(';');
		return {
			key,
			value,
			name,
			isBooleanValue: isBooleanValue === 'true',
			group,
		};
	}

	parseFilterCriteriaArray(filters: string | string[]): FilterCriteria[] {
		if (!filters) {
			return [];
		}

		return [].concat(<any>filters).map(this.parseIndividualFilterCriteria);
	}

	setQueryParamsState(queryParams: { [key: string]: any } = {}): void {
		const {
			inStockProductsOnly,
			filters,
			search,
			target,
			groupId,
			dynamicGroup,
			size,
			page = 1,
			discount,
		} = queryParams;
		const selectedFilters = this.parseFilterCriteriaArray(filters);
		this.setState({
			inStockProductsOnly,
			selectedFilters,
			search,
			target,
			groupId,
			dynamicGroup,
			currentPage: parseInt(page, 10),
			discount,
			...(!!size && { currentPageSize: size }),
		});
	}

	setStateFromServiceResponse(
		searchResponse: SearchResponse = {},
		queryParams: { [key: string]: any } = {},
		routeUrl = '',
		cumulative = false // when sets, appends items to the cumulativeProducts list
	): void {
		const {
			inStockProductsOnly,
			filters,
			page = 1,
			search,
			target = SearchTarget.Search,
			groupId,
			dynamicGroup,
			dasFilter,
		} = queryParams;
		const selectedFilters = this.parseFilterCriteriaArray(filters);
		const dasFilters = this.parseFilterCriteriaArray(dasFilter);
		this.breadcrumb = searchResponse.breadcrumb || {};
		const cumulativeProductItems = cumulative
			? [...(this.state.cumulativeProducts?.items || []), ...(searchResponse.products?.items || [])]
			: [...(searchResponse.products?.items || [])];
		const cppItemCount = (
			cumulativeProductItems?.filter((item) => item.type === 'Product' && !!(<ProductResponse>item).adId) || []
		).length;
		const uniqueDasFacets = this.getUniqueDasFacets(searchResponse?.dasFacets ?? []);
		const { dasFacets, aisleFacets } = this.separateFacets(uniqueDasFacets);

		this.setState({
			...searchResponse,
			cumulativeProducts: {
				items: cumulativeProductItems,
				totalItems: searchResponse.products?.totalItems || 0,
			},
			selectedFilters: selectedFilters,
			currentPage: parseInt(page, 10),
			search,
			groupId,
			dynamicGroup,
			target,
			loading: false,
			scrollToTop: false,
			routeUrl,
			inStockProductsOnly: inStockProductsOnly !== 'false',
			cppItemCount,
			boostOfferTag: searchResponse.products?.items?.length
				? (<ProductResponse[]>searchResponse.products.items)[0].productTag?.boostOffer
				: undefined,
			dasFacets,
			aisleFacets,
			dasFilters,
		});

		if (this.syncDasFacetsWithBreadCrumbShelf && target === SearchTarget.Browse && this.breadcrumb?.shelf?.name) {
			this.setState({
				dasFacets: dasFacets.filter((dasFacet) => dasFacet.name === this.breadcrumb?.shelf?.name),
			});
		}

		this.breadCrumbService.setBreadcrumbs(
			target,
			this.breadcrumb,
			this.getBreadcrumbItemToInsert(),
			this.getBreadcrumbItemToAppend(),
			this.buildQuery()
		);
	}

	getBreadcrumbItemToAppend(): BreadcrumbItem | undefined {
		return this.isSearchTarget ? this.getBreadcrumbItemToAppendForSearch() : undefined;
	}

	getBreadcrumbItemToInsert(): BreadcrumbItem | undefined {
		if (this.isSearchTarget) {
			return this.getBreadcrumbItemToInsertForSearch();
		}

		if (this.isProductGroup) {
			return this.getBreadcrumbItemToInsertForProductGroup();
		}

		if (this.isBoostOffer) {
			return this.getBreadcrumbItemToInsertForBoostOffer();
		}

		return undefined;
	}

	private getBreadcrumbItemToAppendForSearch(): BreadcrumbItem {
		return {
			text: this.state.search,
			routeUrl: undefined,
			queryParams: {},
		};
	}

	private getBreadcrumbItemToInsertForSearch(): BreadcrumbItem {
		return {
			text: 'Search results',
			routeUrl: this.appSettingsService.getSetting('pageUrl').productSearch,
			queryParams: this.buildQuery(undefined, undefined),
		};
	}

	private getBreadcrumbItemToInsertForProductGroup(): BreadcrumbItem {
		return {
			text:
				(this.breadcrumb?.productGroup && this.breadcrumb.productGroup.name) ||
				this.breadcrumb?.dynamicGroup ||
				'',
			routeUrl: this.state.routeUrl,
			queryParams: {},
		};
	}

	private getBreadcrumbItemToInsertForBoostOffer(): BreadcrumbItem {
		const { boostOfferTag } = this.state;
		const offerName = boostOfferTag?.description?.list ?? boostShopRangeDefaultTitle;
		const campaignId = boostOfferTag?.campaignId;
		return {
			text: offerName,
			routeUrl: `/${EDR_BOOST_OFFERS_PATH}/${campaignId}`,
			queryParams: {},
		};
	}

	private getUniqueDasFacets(dasFacets: FilterCriteria[]): FilterCriteria[] {
		return dasFacets.reduce((acc: FilterCriteria[], current) => {
			if (!acc.find((item) => item.value === current.value)) {
				acc.push(current);
			}
			return acc;
		}, []);
	}

	private separateFacets(dasFacets: FilterCriteria[]): {
		dasFacets: FilterCriteria[];
		aisleFacets: FilterCriteria[];
	} {
		const notAisleOnly = dasFacets.some((item) => item.group !== DAS.Aisle);

		if (notAisleOnly) {
			return dasFacets.reduce(
				(acc: { dasFacets: FilterCriteria[]; aisleFacets: FilterCriteria[] }, current) => {
					if (current.group !== DAS.Aisle) {
						acc.dasFacets.push(current);
					} else {
						acc.aisleFacets.push(current);
					}
					return acc;
				},
				{ dasFacets: [], aisleFacets: [] }
			);
		} else {
			return {
				dasFacets,
				aisleFacets: dasFacets,
			};
		}
	}
}
