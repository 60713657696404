/* SVG Icon Names 

alarm
alert
alert-filled
android
arrow-left
atm
avocado
basil
bookmark
bookmark-circle
bookmark-circle-a
bookmark-circle-filled
bookmark-filled
boost
bowl
browse
buddy
byobags-deselected
byobags-selected
car
card-unit
cart-default
cart-filled
casserole
change-order
chat
chevron-bottom
chevron-left
chevron-right
chevron-top
christmas
christmas-shop
circle-with-tick
close
close-filled
contact-career
contact-media
contact-physical
contact-postal
contact-scam
contact-supplier
copy
cost
credit-card-a
cross
cutlery
default-service
delivery-saver
delivery-truck
delivery-truck-filled
desktop-smartphone
direct-debit
direct-to-boot
direct-to-boot-front
doc-marker
dollar-sign
donate
edit
edr-boosts
email
ev-charging
events
express-delivery-truck
express-shopping-bags
external-link
facebook
filled-circle
folded-page
fresh-flowers
fuel-empty
fuel-full
fun-green-arc
glass-containers
gold-card
graph
green-checked-filled
happy-chat
heart
heart-default
heart-filled
heart-thick
hide
hint
hint-light
hint-light-green
home
information
item-changed
knife
leaf-logomark
light-orange-arc
list
location-pin
location-pin-a
location-pin-filled
lock
locker-screen
lotto
meal-serving
menu
menu-rounded
minus
mob-chat
move-to-bottom
notes-default
notes-filled
onecard
onecard-active
onecard-empty
onecard-spend
onecard-spend-alt
outgoing-link
paperbags-deselected
paperbags-selected
past-orders
pause
payment-card
perishable-items
pick-up
pick-up-filled
pickup-wapple
pinterest
plastic-recycling
play
plus
print
pulse
quantity-picker
recipes
remove-my-favourites
restricted-items
rocket
round-checked-filled
round-outline
roundel-only
rug-doctor
saving
scan-and-go
scan-to-trolley
search-icon
share
shipping
shipping-wapple
shopping-bags
shopping-list
show
sms
soup-bowl-steaming
specials
star
temp-out-of-stock
thumbs-down
thumbs-up
tick
tick-sign
tick-thicker
time
trash
trolley
trolley-filled
truck-delivery
twitter
typing-chat
user
user-card-a
voucher
voucher-multiple
wall-clock
wapple
*/

/* eslint-disable @typescript-eslint/naming-convention */
export enum SVGIcon {
	Alarm = 'alarm',
	Alert = 'alert',
	AlertFilled = 'alert-filled',
	Android = 'android',
	ArrowLeft = 'arrow-left',
	Atm = 'atm',
	Avocado = 'avocado',
	Basil = 'basil',
	Bookmark = 'bookmark',
	BookmarkCircle = 'bookmark-circle',
	BookmarkCircleA = 'bookmark-circle-a',
	BookmarkCircleFilled = 'bookmark-circle-filled',
	BookmarkFilled = 'bookmark-filled',
	Boost = 'boost',
	Bowl = 'bowl',
	Browse = 'browse',
	Buddy = 'buddy',
	ByobagsDeselected = 'byobags-deselected',
	ByobagsSelected = 'byobags-selected',
	Car = 'car',
	CardUnit = 'card-unit',
	CartDefault = 'cart-default',
	CartFilled = 'cart-filled',
	Casserole = 'casserole',
	ChangeOrder = 'change-order',
	Chat = 'chat',
	ChevronBottom = 'chevron-bottom',
	ChevronLeft = 'chevron-left',
	ChevronRight = 'chevron-right',
	ChevronTop = 'chevron-top',
	Christmas = 'christmas',
	ChristmasShop = 'christmas-shop',
	CircleWithTick = 'circle-with-tick',
	Close = 'close',
	CloseFilled = 'close-filled',
	ContactCareer = 'contact-career',
	ContactMedia = 'contact-media',
	ContactPhysical = 'contact-physical',
	ContactPostal = 'contact-postal',
	ContactScam = 'contact-scam',
	ContactSupplier = 'contact-supplier',
	Copy = 'copy',
	Cost = 'cost',
	CreditCardA = 'credit-card-a',
	Cross = 'cross',
	Cutlery = 'cutlery',
	DefaultService = 'default-service',
	DeliverySaver = 'delivery-saver',
	DeliveryTruck = 'delivery-truck',
	DeliveryTruckFilled = 'delivery-truck-filled',
	DesktopSmartphone = 'desktop-smartphone',
	DirectDebit = 'direct-debit',
	DirectToBoot = 'direct-to-boot',
	DirectToBootFront = 'direct-to-boot-front',
	DocMarker = 'doc-marker',
	DollarSign = 'dollar-sign',
	Donate = 'donate',
	Edit = 'edit',
	EdrBoosts = 'edr-boosts',
	Email = 'email',
	EvCharging = 'ev-charging',
	Events = 'events',
	ExpressDeliveryTruck = 'express-delivery-truck',
	ExpressShoppingBags = 'express-shopping-bags',
	ExternalLink = 'external-link',
	Facebook = 'facebook',
	FilledCircle = 'filled-circle',
	FoldedPage = 'folded-page',
	FreshFlowers = 'fresh-flowers',
	FuelEmpty = 'fuel-empty',
	FuelFull = 'fuel-full',
	FunGreenArc = 'fun-green-arc',
	GlassContainers = 'glass-containers',
	GoldCard = 'gold-card',
	Graph = 'graph',
	GreenCheckedFilled = 'green-checked-filled',
	HappyChat = 'happy-chat',
	Heart = 'heart',
	HeartDefault = 'heart-default',
	HeartFilled = 'heart-filled',
	HeartThick = 'heart-thick',
	Hide = 'hide',
	Hint = 'hint',
	HintLight = 'hint-light',
	HintLightGreen = 'hint-light-green',
	Home = 'home',
	Information = 'information',
	ItemChanged = 'item-changed',
	Knife = 'knife',
	LeafLogomark = 'leaf-logomark',
	LightOrangeArc = 'light-orange-arc',
	List = 'list',
	LocationPin = 'location-pin',
	LocationPinA = 'location-pin-a',
	LocationPinFilled = 'location-pin-filled',
	Lock = 'lock',
	LockerScreen = 'locker-screen',
	Lotto = 'lotto',
	MealServing = 'meal-serving',
	Menu = 'menu',
	MenuRounded = 'menu-rounded',
	Minus = 'minus',
	MobChat = 'mob-chat',
	MoveToBottom = 'move-to-bottom',
	NotesDefault = 'notes-default',
	NotesFilled = 'notes-filled',
	Onecard = 'onecard',
	OnecardActive = 'onecard-active',
	OnecardEmpty = 'onecard-empty',
	OnecardSpend = 'onecard-spend',
	OnecardSpendAlt = 'onecard-spend-alt',
	OutgoingLink = 'outgoing-link',
	PaperbagsDeselected = 'paperbags-deselected',
	PaperbagsSelected = 'paperbags-selected',
	PastOrders = 'past-orders',
	Pause = 'pause',
	PaymentCard = 'payment-card',
	PerishableItems = 'perishable-items',
	PickUp = 'pick-up',
	PickUpFilled = 'pick-up-filled',
	PickupWapple = 'pickup-wapple',
	Pinterest = 'pinterest',
	PlasticRecycling = 'plastic-recycling',
	Play = 'play',
	Plus = 'plus',
	Print = 'print',
	Pulse = 'pulse',
	QuantityPicker = 'quantity-picker',
	Recipes = 'recipes',
	RemoveMyFavourites = 'remove-my-favourites',
	RestrictedItems = 'restricted-items',
	Rocket = 'rocket',
	RoundCheckedFilled = 'round-checked-filled',
	RoundOutline = 'round-outline',
	RoundelOnly = 'roundel-only',
	RugDoctor = 'rug-doctor',
	Saving = 'saving',
	ScanAndGo = 'scan-and-go',
	ScanToTrolley = 'scan-to-trolley',
	SearchIcon = 'search-icon',
	Share = 'share',
	Shipping = 'shipping',
	ShippingWapple = 'shipping-wapple',
	ShoppingBags = 'shopping-bags',
	ShoppingList = 'shopping-list',
	Show = 'show',
	Sms = 'sms',
	SoupBowlSteaming = 'soup-bowl-steaming',
	Specials = 'specials',
	Star = 'star',
	TempOutOfStock = 'temp-out-of-stock',
	ThumbsDown = 'thumbs-down',
	ThumbsUp = 'thumbs-up',
	Tick = 'tick',
	TickSign = 'tick-sign',
	TickThicker = 'tick-thicker',
	Time = 'time',
	Trash = 'trash',
	Trolley = 'trolley',
	TrolleyFilled = 'trolley-filled',
	TruckDelivery = 'truck-delivery',
	Twitter = 'twitter',
	TypingChat = 'typing-chat',
	User = 'user',
	UserCardA = 'user-card-a',
	Voucher = 'voucher',
	VoucherMultiple = 'voucher-multiple',
	WallClock = 'wall-clock',
	Wapple = 'wapple',
	Warning = 'warning',
}
