export enum FlagKey {
	aaTestForFeatureExperimentation = 'a_a_test_for_feature_experimentation',
	boughtBeforeInSpecials = 'bought_before_in_specials',
	browseMenuHoverExperiment = 'browse_menu__enable_hover_on_submenu',
	expressLandingPageNew = 'feature-enablenewexpresslandingpage',
	globalNavExperiment = 'bigger_search_box',
	infiniteLoadMore = 'infinite_load_more',
	traderNewRegistrationFlow = 'trader_new_registration_flow',
	searchRelevanceExperiment = 'search_relevance_experiment',
	cartologyMegaMenuFeatureBrandTileExperiment = 'cart_ab-114_-_mege_menu_feature_brand_tiles',
	pastOrdersFavouritesBannerExperiment = 'past_orders_favourites_banner_experiment',
	megaMenuRecipeExperiment = 'mega_menu_recipe_experiment',
	newLayoutOfProductCategoriesAndFilters = 'ab146_new_layout_of_product_categories_and_filters',
	newLayoutOfProductCategoriesAndFiltersExp2 = 'ab150_new_layout_of_product_categories_and_filters_experiment_2',
	newLayoutOfProductCategoriesAndFiltersExp3 = 'ab152_new_layout_of_product_categories_and_filters_experiment_3',
	boostShopNowUseDestinationUrl = 'boost-shop-now-use-destination-url',
	expressLimitStickAlertMessageExperiment = 'ab-155__pickupx__express_pickup_30_item_limit',
	currentDayAvailabilityExperiment = 'current_day_availability_experiment',
	megaMenuProductDiscovery = "ab-166__go-cart__mega_menu_product_discovery",
}
