import {
	CdkPortal,
	CdkPortalOutlet,
	ComponentPortal,
	ComponentType,
	DomPortalOutlet,
	PortalInjector,
} from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import {
	ApplicationRef,
	ComponentFactoryResolver,
	ComponentRef,
	ElementRef,
	Inject,
	Injectable,
	InjectionToken,
	Injector,
	Renderer2,
	RendererFactory2,
	TemplateRef,
	Type,
} from '@angular/core';

export const PORTAL_DATA = new InjectionToken<PortalData>('PortalData');

@Injectable({
	providedIn: 'root',
})
export class PortalData {
	[x: string]: any;
}

interface HostMap {
	[x: string]: DomPortalOutlet;
}

export const PORTAL_PROVIDER = {
	provide: PORTAL_DATA,
	useClass: PortalData,
};

export type Content<T> = string | TemplateRef<T> | Type<T>;

@Injectable({
	providedIn: 'root',
})
export class PortalService {
	private portal: ComponentPortal<any> | CdkPortal;
	private hosts: HostMap = {};
	private renderer: Renderer2;

	constructor(
		private componentFactoryResolver: ComponentFactoryResolver,
		private appRef: ApplicationRef,
		private injector: Injector,
		@Inject(DOCUMENT) private document: Document,
		rendererFactory: RendererFactory2
	) {
		this.renderer = rendererFactory.createRenderer(null, null);
	}

	initDomPortal = (
		id: ElementRef | string,
		componentType: ComponentType<any>,
		portalData?: any,
		idName = ''
	): ComponentRef<any> | undefined => {
		let container;
		if (typeof id != 'string') {
			container = id.nativeElement;
		} else {
			container = this.document?.getElementById(id);
			idName = id;
		}

		if (!container) {
			return;
		}

		this.renderer.setAttribute(container, 'data-layout-id', portalData?.id || '');
		this.renderer.setProperty(container, 'innerHTML', '');

		this.portal = portalData
			? new ComponentPortal(componentType, null, this.createInjector(portalData))
			: new ComponentPortal(componentType);

		this.hosts[idName] = new DomPortalOutlet(container, this.componentFactoryResolver, this.appRef, this.injector);

		return this.hosts[idName].attach(this.portal);
	};

	clearDomPortal(id: string): void {
		if (!this.hosts[id]) {
			return;
		}

		const container = this.hosts[id].outletElement;

		this.renderer.setAttribute(container, 'data-layout-id', '');

		this.hosts[id]?.detach();
	}

	// this makes the assumption that the passed in outlet has already been cleaned up. Should probably be modified to hold the outlet ref in hostsMap and clean up itself
	initComponentPortal = (
		componentType: ComponentType<any>,
		portalData: any,
		outlet: CdkPortalOutlet
	): ComponentRef<any> => {
		this.portal = new ComponentPortal(componentType, null, this.createInjector(portalData));

		return outlet.attachComponentPortal(this.portal);
	};

	render = (renderFn: Function): Function => renderFn;

	private createInjector(data: any): PortalInjector {
		const injectorTokens = new WeakMap<any, any>([[PORTAL_DATA, data]]);
		return new PortalInjector(this.injector, injectorTokens);
	}
}
