import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { filter, take } from 'rxjs/operators';
import { FlagKey, FlagService, CurrentDayAvailabilityExperimentVariants } from './optimizely';

@Injectable({
	providedIn: 'root',
})
export class CurrentDayAvailabilityExperimentService {
	constructor(private cookieService: CookieService, private flagService: FlagService) {}

	setExperimentCookies(): void {
		this.flagService
			.getVariationKey(FlagKey.currentDayAvailabilityExperiment)
			.pipe(
				take(1),
				filter((variationKey) => variationKey === CurrentDayAvailabilityExperimentVariants.Control)
			)
			.subscribe(() => {
				if (this.cookieService.check(`cw-${FlagKey.currentDayAvailabilityExperiment}`) === false) {
					this.cookieService.set(`cw-${FlagKey.currentDayAvailabilityExperiment}`, '1');
				}
			});
	}
}
